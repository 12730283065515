<div class="responsive-container">
  @switch (billingAccountService.currentState()) {
    @case ('LOADING') {
      <fly-full-page-loader type="dark"> </fly-full-page-loader>
    }
    @case ('ERROR') {
      <g-heading
        size="1"
        content="An unknown error occurred">
      </g-heading>
      <span>
        Please try again or contact
        <a routerLink="/aviation-support">Aviation Product Support</a>
      </span>
    }
    @case ('LOADED') {
      <fly-subscription-management-page-header
        [title]="title"
        [subscriptionType]="subscriptionType"
        [currentBillingAccount]="billingAccountService.currentBillingAccount()"
        [multipleBillingAccounts]="billingAccountService.hasMultipleBillingAccounts()"
        [showBillingSection]="showBillingSection()">
        <ng-content
          select="[icon]"
          icon>
        </ng-content>
        <ng-content
          select="[button]"
          button>
        </ng-content>
      </fly-subscription-management-page-header>
      <ng-content select="[contentBody]"></ng-content>
    }
  }
</div>
