import { Component, DestroyRef, effect, inject, input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, FormRecord } from '@angular/forms';
import { AvdbDeviceIdType, CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { forkJoin, take } from 'rxjs';
import { AvdbSubscriptionType, AvdbSubscriptionTypeEnum } from '../../../../shared/enums/avdb-subscription-type.enum';
import { Device } from '../../../../shared/models/device/device.model';
import { AvdbSubscriptionCard } from '../../../../shared/models/subscriptions/avdb-subscription-card.model';
import { DeviceService } from '../../../../shared/services/device.service';
import { FilterName } from '../../enums/filter-name.enum';
import { SortOption } from '../../enums/sort-option.enum';
import { FilterOption } from '../../models/filter-name.model';
import { Filter } from '../../models/filter.model';
import { AvdbSubscriptionCardsFilterAndSortService } from '../../services/avdb-subscription-cards-filter-and-sort.service';

enum State {
  LOADING,
  LOADED,
  ERROR
}

@Component({
  selector: 'fly-avdb-subscriptions-filter-and-sort-menu',
  templateUrl: './avdb-subscriptions-filter-and-sort-menu.component.html',
  styleUrls: ['../../../../../stylesheets/subscriptions-sort-and-filter-menu.scss'],
  standalone: false
})
export class AvdbSubscriptionsFilterAndSortMenuComponent implements OnInit, OnDestroy {
  avdbSubscriptionCards = input.required<AvdbSubscriptionCard[] | null>();
  filters: Filter[] = [];
  currentState: State = State.LOADING;
  readonly FilterName = FilterName;
  readonly SortOption = SortOption;
  readonly State = State;
  readonly formGroup = new FormGroup({
    sortOption: new FormControl<SortOption>(SortOption.DEFAULT, { nonNullable: true }),
    aircraftFilters: new FormRecord<FormControl<boolean>>({}),
    portableDeviceFilters: new FormRecord<FormControl<boolean>>({}),
    subscriptionTypeFilters: new FormRecord<FormControl<boolean>>({})
  });
  private readonly avdbSubscriptionCardsFilterAndSortService = inject(AvdbSubscriptionCardsFilterAndSortService);
  private readonly deviceService = inject(DeviceService);
  private readonly destroyRef = inject(DestroyRef);

  effect = effect(() => {
    this.currentState = State.LOADING;
    const cards = this.avdbSubscriptionCards();
    if (cards != null) {
      forkJoin({
        avdbSubscriptionCards: this.avdbSubscriptionCardsFilterAndSortService
          .filterAndSortSubscriptionCards(cards)
          .pipe(takeUntilDestroyed(this.destroyRef), take(1)),
        userPortableDevices: this.deviceService.getPortables()
      }).subscribe({
        next: (data) => {
          const { avdbSubscriptionCards, userPortableDevices: portableDevices } = data;
          this.initializeFilters(avdbSubscriptionCards, portableDevices);
          this.initializeFormGroup();
          this.currentState = State.LOADED;
        },
        error: () => (this.currentState = State.ERROR)
      });
    }
  });

  ngOnInit(): void {
    this.initializeFormSubscriptions();
  }

  ngOnDestroy(): void {
    this.avdbSubscriptionCardsFilterAndSortService.resetFiltersAndSorting();
  }

  private initializeFilters(avdbSubscriptionCards: AvdbSubscriptionCard[], portableDevices: Device[]): void {
    this.filters = [
      {
        name: FilterName.AIRCRAFT,
        options: this.generateAircraftFilterOptions(avdbSubscriptionCards)
      },
      {
        name: FilterName.PORTABLE,
        options: this.generatePortableDeviceFilterOptions(avdbSubscriptionCards, portableDevices)
      },
      {
        name: FilterName.SUBSCRIPTION,
        options: this.generateSubscriptionTypeFilterOptions(avdbSubscriptionCards)
      }
    ].filter((filter) => filter.options.length > 0);
  }

  private initializeFormGroup(): void {
    this.addFilterToFormGroup(FilterName.AIRCRAFT, this.formGroup.controls.aircraftFilters);
    this.addFilterToFormGroup(FilterName.PORTABLE, this.formGroup.controls.portableDeviceFilters);
    this.addFilterToFormGroup(FilterName.SUBSCRIPTION, this.formGroup.controls.subscriptionTypeFilters);
  }

  private addFilterToFormGroup(filterName: FilterName, formGroup: FormRecord<FormControl<boolean>>): void {
    const existingFilter = this.filters.find((filter) => filter.name === filterName);
    if (existingFilter != null) {
      existingFilter.options.forEach((option) => {
        formGroup.addControl(option.value, new FormControl(false, { nonNullable: true }), { emitEvent: false });
      });
    }
  }

  private subscribeToFormControlChanges(control: AbstractControl, filterName: FilterName): void {
    control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((filters) => {
      const selectedFilters = Object.keys(filters).filter((filter) => filters[filter]);
      this.avdbSubscriptionCardsFilterAndSortService.updateFilter(filterName, selectedFilters);
    });
  }

  private subscribeToSortOptionChanges(control: AbstractControl): void {
    control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((sortOption) => {
      this.avdbSubscriptionCardsFilterAndSortService.updateSortOption(sortOption);
    });
  }

  private initializeFormSubscriptions(): void {
    this.subscribeToFormControlChanges(this.formGroup.controls.aircraftFilters, FilterName.AIRCRAFT);
    this.subscribeToFormControlChanges(this.formGroup.controls.portableDeviceFilters, FilterName.PORTABLE);
    this.subscribeToFormControlChanges(this.formGroup.controls.subscriptionTypeFilters, FilterName.SUBSCRIPTION);
    this.subscribeToSortOptionChanges(this.formGroup.controls.sortOption);
  }

  private generateAircraftFilterOptions(avdbSubscriptionCards: AvdbSubscriptionCard[]): FilterOption[] {
    const aircraftCounts = new Map<string, number>();
    for (const avdbSubscriptionCard of avdbSubscriptionCards) {
      if (avdbSubscriptionCard.aircraftTailNumber != null) {
        aircraftCounts.set(
          avdbSubscriptionCard.aircraftTailNumber,
          (aircraftCounts.get(avdbSubscriptionCard.aircraftTailNumber) ?? 0) + 1
        );
      }
    }

    const aircraftFilterOptions = Array.from(aircraftCounts, ([tailNumber, count]) => ({
      name: tailNumber,
      value: tailNumber,
      count: count
    }));

    return aircraftFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  private generatePortableDeviceFilterOptions(
    avdbSubscriptionCards: AvdbSubscriptionCard[],
    userPortableDevices: Device[]
  ): FilterOption[] {
    const portableDeviceCounts = new Map<Device, number>();

    for (const avdbSubscriptionCard of avdbSubscriptionCards) {
      const subscriptionPortableDevices = avdbSubscriptionCard.subscriptionData.devices?.filter((device) => {
        return device.idType === AvdbDeviceIdType.PORTABLE_DEVICE_SERIAL_NUMBER;
      });

      if (subscriptionPortableDevices == null) {
        return [];
      }

      // Match each portable device on the subscription to a portable device that the user owns
      for (const subscriptionPortableDevice of subscriptionPortableDevices) {
        const matchedPortableDevice = userPortableDevices.find((userPortableDevice) => {
          return userPortableDevice.displaySerial === subscriptionPortableDevice.id;
        });

        if (matchedPortableDevice != null) {
          portableDeviceCounts.set(matchedPortableDevice, (portableDeviceCounts.get(matchedPortableDevice) ?? 0) + 1);
        }
      }
    }

    const portableDeviceFilterOptions: FilterOption[] = [];
    portableDeviceCounts.forEach((portableDeviceCount, portableDevice) => {
      portableDeviceFilterOptions.push({
        name: `${portableDevice.name} (${portableDevice.displaySerial})`,
        value: portableDevice.displaySerial,
        count: portableDeviceCount
      });
    });

    return portableDeviceFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  private generateSubscriptionTypeFilterOptions(avdbSubscriptionCards: AvdbSubscriptionCard[]): FilterOption[] {
    const subscriptionTypeCounts = new Map<AvdbSubscriptionType, number>();
    for (const avdbSubscriptionCard of avdbSubscriptionCards) {
      if (avdbSubscriptionCard.product.productType === CartItemType.DATABASE) {
        subscriptionTypeCounts.set(
          AvdbSubscriptionTypeEnum.INDIVIDUAL_DATABASE,
          (subscriptionTypeCounts.get(AvdbSubscriptionTypeEnum.INDIVIDUAL_DATABASE) ?? 0) + 1
        );
      } else if (avdbSubscriptionCard.product.bundleType != null) {
        subscriptionTypeCounts.set(
          avdbSubscriptionCard.product.bundleType,
          (subscriptionTypeCounts.get(avdbSubscriptionCard.product.bundleType) ?? 0) + 1
        );
      }
    }

    const subscriptionTypeFilterOptions: FilterOption[] = [];
    subscriptionTypeCounts.forEach((subscriptionTypeCount, subscriptionType) => {
      subscriptionTypeFilterOptions.push({
        name: subscriptionType,
        value: subscriptionType,
        count: subscriptionTypeCount
      });
    });

    return subscriptionTypeFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }
}
