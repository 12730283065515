<fly-modal
  #modal
  [header]="'REDEEM PROMO CODE'"
  [buttons]="[
    {
      text: 'Close',
      action: 'close'
    }
  ]">
  <div slot="body">
    <div class="content">
      <fly-promo-code-wizard
        [customerGuid]="customerGuid"
        (manageDevices)="navigateToManageMobileDevices($event)">
      </fly-promo-code-wizard>
    </div>
  </div>
</fly-modal>
