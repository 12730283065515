<fly-subscription-card>
  <ng-container alert>
    <fly-subscription-card-alert
      [pendingJobAction]="cardData.pendingJobAction"
      [pendingJob]="cardData.pendingJob"
      [(showAutoRenewalUpdateError)]="showAutoRenewalUpdateError">
    </fly-subscription-card-alert>
  </ng-container>

  <ng-container firstContent>
    <!-- The styling for the skeleton card is broken up into firstContent and lastContent
    because the contents of fly-subscription-card are projected into those ng-containers -->
    <span class="subscription-card--title">{{ cardData.product.display }}</span>
    @if (cardData.product.avdbType != null) {
      <g-flex class="subscription-card--small-text">
        <span class="no-wrap-text">{{ cardData.product.avdbType }}</span>
      </g-flex>
    }
    @if (cardData.aircraftTailNumber != null) {
      <g-flex class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Covered Aircraft:</span>
        @if (cardData.aircraftLinkUrl != null) {
          <a
            [href]="cardData.aircraftLinkUrl"
            class="no-wrap-text">
            {{ cardData.aircraftTailNumber }}
          </a>
        }
      </g-flex>
    }
    @if (cardData.deviceName != null && cardData.deviceLinkUrl != null && cardData.deviceSerial) {
      <div class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Covered Device:</span>
        <a
          [href]="cardData.deviceLinkUrl"
          class="no-wrap-text">
          {{ cardData.deviceName }} ({{ cardData.deviceSerial }})
        </a>
      </div>
    }
    <div>
      <ng-template [ngTemplateOutlet]="renewalDate"></ng-template>
      <ng-template [ngTemplateOutlet]="expirationDate"></ng-template>
    </div>
    @if (
      cardData.subscriptionData.canRenewNow &&
      cardData.subscriptionData.renewalPrice != null &&
      cardData.subscriptionData.renewalPrice.amount > 0 &&
      yarmouthRenewalPriceEnabled
    ) {
      <div class="subscription-card--field-value-text subscription-card--small-text">
        <span class="subscription-card--bold-text no-wrap-text">Renewal Price:</span>
        <span class="no-wrap-text">{{
          cardData.subscriptionData.renewalPrice.amount | currency: cardData.subscriptionData.renewalPrice.currency
        }}</span>
      </div>
    }
    @if (cardData.subscriptionData.canRenewNow && addToCurrentCartFailed) {
      <div>
        <g-error class="error">
          An error occurred while adding this item to the cart. Please try again, or contact
          <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
        </g-error>
      </div>
    } @else if (hasFutureStartDate && updateStartDateFailed) {
      <div>
        <g-error class="error">
          An error occurred while updating the subscription's start date. Please try again, or contact
          <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
        </g-error>
      </div>
    }
  </ng-container>

  @if (isBundleImprovementsEnabled) {
    <div
      secondContent
      class="second-content">
      @if (cardData.subscriptionData.isRedeemed) {
        <a
          class="options"
          [routerLink]="'/products/databases/coverage-selection/'"
          [queryParams]="{ subscriptionUuid: this.cardData.subscriptionData.subscriptionUuid }">
          <g-button
            class="subscription-card--button"
            size="medium"
            theme="light"
            type="button"
            [disabled]="cardData.pendingJob">
            UPDATE OPTIONS
          </g-button>
        </a>
      }
    </div>
  }

  <ng-container lastContent>
    <div class="last-content">
      @if (cardData.subscriptionData.renewable) {
        <fly-auto-renewal-toggle
          [toggleData]="cardData"
          [pending]="cardData.pendingJob"
          (autoRenewalUpdateRequestSucceeded)="handleAutoRenewalUpdateResponse($event)"
          (subscriptionJobType)="updateSubscriptionJobType($event)">
        </fly-auto-renewal-toggle>
      }
      @if (cardData.subscriptionData.canRenewNow) {
        <fly-renew-subscription-button
          [cardData]="cardData"
          [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
          [presentInCurrentCart]="presentInCurrentCart"
          (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
        </fly-renew-subscription-button>
      }
      @if (cardData.subscriptionData.autorenewalEnabled) {
        <g-button
          theme="light"
          (click)="cancel.open()"
          type="button"
          [disabled]="cardData.pendingJob">
          CANCEL
        </g-button>
      }
      @if (earlyRenewalEnabled && cardData.subscriptionData.redeemable && !cardData.subscriptionData.isRedeemed) {
        @if (cardData.aircraftLinkUrl != null) {
          <g-button
            [theme]="'dark'"
            target="_blank"
            [href]="cardData.aircraftLinkUrl"
            >Redeem Now
          </g-button>
        } @else if (cardData.deviceLinkUrl != null) {
          <g-button
            [theme]="'dark'"
            target="_blank"
            [href]="cardData.deviceLinkUrl"
            >Redeem Now
          </g-button>
        }
      }
    </div>
  </ng-container>

  <ng-template #expirationDate>
    @if (cardData.subscriptionData.endDate != null && (cardData.subscriptionData.endDate | flyIsStringNonEmpty)) {
      <div class="subscription-card--field-value-text subscription-card--small-text">
        <span class="subscription-card--bold-text no-wrap-text">Expiration Date:</span>
        <span class="no-wrap-text">{{ cardData.subscriptionData.endDate | flyFormattedDate }}</span>
      </div>
    }
  </ng-template>

  <ng-template #renewalDate>
    @if (hasFutureStartDate && !cardData.pendingJob) {
      @if (cardData.subscriptionData.startDate != null) {
        <div class="subscription-card--field-value-text subscription-card--small-text">
          <span class="subscription-card--bold-text no-wrap-text">Plan Starts:</span>
          <span class="no-wrap-text">{{ cardData.subscriptionData.startDate | flyFormattedDate }}</span>
          @if (!subSourceGrantTypes.includes(cardData.subscriptionData.source)) {
            <button
              class="link-styled-button"
              (click)="startNow.open()">
              Start Now
            </button>
          }
        </div>
      }
    } @else {
      @if (cardData.subscriptionData.nextBillDate | flyIsStringNonEmpty) {
        <div class="subscription-card--field-value-text subscription-card--small-text">
          <span class="subscription-card--bold-text no-wrap-text">Plan Renews:</span>
          <span class="no-wrap-text">{{ cardData.subscriptionData.nextBillDate | flyFormattedDate }}</span>
        </div>
      }
    }
  </ng-template>
</fly-subscription-card>

<fly-cancel-subscription-modal
  #cancel
  [toggleData]="cardData"
  [pending]="cardData.pendingJob"
  [deviceId]="deviceId"
  (autoRenewalUpdateRequestSucceeded)="handleAutoRenewalUpdateResponse($event)"
  (subscriptionJobType)="updateSubscriptionJobType($event)">
</fly-cancel-subscription-modal>

<fly-start-subscription-confirmation-modal
  #startNow
  [subscriptionUuid]="cardData.subscriptionData.subscriptionUuid">
</fly-start-subscription-confirmation-modal>
