<ng-template #closeBillingSelection>
  <div class="close-billing-account-container">
    <g-button
      (click)="router.navigateByUrl(previousUrl)"
      size="medium"
      theme="dark"
      type="button">
      RETURN
    </g-button>
  </div>
</ng-template>

<div class="billing-account-container">
  @if (billingAccountService.currentState() === 'LOADED') {
    <div class="billing-account-container">
      <h1 class="billing-account-header">Select an Account</h1>
      <p class="billing-account-description">
        @if (billingAccountService.hasMultipleBillingAccounts()) {
          Your Garmin account is associated with more than one billing account. Please select the account you would like
          to manage.
        } @else if (billingAccountService.billingAccounts()?.length === 1) {
          Your Garmin account is associated with only one billing account.
        } @else {
          Your Garmin account is not associated with any billing account.
        }
      </p>
    </div>
  } @else if (billingAccountService.currentState() === 'LOADING') {
    <fly-full-page-loader></fly-full-page-loader>
  } @else {
    <g-alert
      closeable="false"
      type="error"
      slotAlign="center">
      An error occurred while loading your billing accounts. Please try again.
    </g-alert>
  }

  @if (billingAccountService.currentBillingAccount(); as currentBillingAccount) {
    <div class="billing-account-list-container"></div>
    @for (billingAccount of billingAccountService.billingAccounts(); track billingAccount.id) {
      <div class="billing-account-list-item-container">
        <div>{{ billingAccount | flyAccountDisplay }}</div>
        <div class="billing-account-list-item">
          @if (currentBillingAccount === billingAccount) {
            <p>
              <!-- Checkmark icon -->
              <fa-icon
                [icon]="checkIcon"
                alt="Selected"></fa-icon>
              SELECTED
            </p>
          }
          @if (currentBillingAccount !== billingAccount) {
            <g-button
              (click)="
                billingAccountService.currentBillingAccount.set(billingAccount); router.navigateByUrl(previousUrl)
              "
              size="medium"
              theme="light"
              type="button">
              SELECT
            </g-button>
          }
        </div>
      </div>
    }
  }
  <ng-container [ngTemplateOutlet]="closeBillingSelection"></ng-container>
</div>
