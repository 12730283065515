<ng-template #loading>
  <fly-full-page-loader></fly-full-page-loader>
</ng-template>

<ng-template #error>
  <span>
    An error occurred while fetching the subscription data. Please refresh or contact
    <a routerLink="/aviation-support">Aviation Product Support</a>
    if the issue continues.
  </span>
</ng-template>

<g-flex class="subpage-container">
  @if (currentState() === 'LOADING') {
    <ng-container [ngTemplateOutlet]="loading"></ng-container>
  } @else if (currentState() === 'ERROR') {
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  } @else if (currentState() === 'LOADED') {
    <div class="subpage-container--main-content">
      @if (activeAvdbSubscriptionCards()?.length === 0 && expiredAvdbSubscriptionCards()?.length === 0) {
        <p>
          You do not have any subscriptions to manage. To obtain Database subscriptions, visit the
          <a [href]="catalogUrl">database purchase</a>
          page to purchase new subscriptions.
        </p>
      } @else {
        <fly-tabs
          [tabs]="tabs"
          class="tabs">
        </fly-tabs>
        <div class="subpage-container--subscriptions">
          @if (!isWebview) {
            @if (selectedTabRoute === tabs[0].route) {
              <fly-avdb-subscriptions-filter-and-sort-menu
                class="filter"
                [avdbSubscriptionCards]="activeAvdbSubscriptionCards()">
              </fly-avdb-subscriptions-filter-and-sort-menu>
            } @else {
              <fly-avdb-subscriptions-filter-and-sort-menu
                class="filter"
                [avdbSubscriptionCards]="expiredAvdbSubscriptionCards()">
              </fly-avdb-subscriptions-filter-and-sort-menu>
            }
          }
          <div class="tab-list-container">
            @if (selectedTabRoute === tabs[0].route) {
              <fly-avdb-subscriptions-list
                [avdbSubscriptionCards]="activeAvdbSubscriptionCards()"
                [currentCart]="currentCart()"
                class="subscription-list">
              </fly-avdb-subscriptions-list>
            } @else {
              <fly-avdb-subscriptions-list
                [avdbSubscriptionCards]="expiredAvdbSubscriptionCards()"
                [currentCart]="currentCart()"
                class="subscription-list">
              </fly-avdb-subscriptions-list>
            }
          </div>
        </div>
      }
    </div>
  }
</g-flex>
