export const CART_POLLING_INTERVAL_SECONDS = 15;

export const ORDER_PROCESSED_MESSAGE =
  'Your order has been successfully processed. ' +
  'If you do not see your purchased items below, ' +
  'please refresh your browser.';

export const ORDER_PROCESSING_MESSAGE =
  'Thank you for your recent purchase. ' +
  'We are still processing your order, and it may take a few minutes for your purchased items to display.';
