import { Component, Input } from '@angular/core';
import { BillingAccount } from 'src/app/shared/models/subscriptions/billing-account.model';

@Component({
  selector: 'fly-subscription-management-page-header',
  templateUrl: './subscription-management-page-header.component.html',
  styleUrls: ['./subscription-management-page-header.component.scss'],
  standalone: false
})
export class SubscriptionManagementPageHeaderComponent {
  @Input({ required: true }) title: string;
  @Input({ required: true }) currentBillingAccount: BillingAccount | null;
  @Input({ required: true }) multipleBillingAccounts: boolean;
  @Input({ required: true }) showBillingSection: boolean;
  @Input({ required: true }) subscriptionType: 'GP' | 'DATABASES';
}
