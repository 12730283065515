@if (billingAccountService.currentState() === 'LOADED') {
  <div class="invoices-container">
    <div class="invoices-flex-col">
      <div class="invoices-flex-col">
        <h1 class="title">Invoices</h1>
        <div class="invoices-options-container">
          <div class="invoices-info">
            @if (billingAccountService.currentBillingAccount(); as currentBillingAccount) {
              <span class="account-label">Billing Account</span>
              <span class="account-info">{{ currentBillingAccount | flyAccountDisplay }}</span>
            }
            @if (billingAccountService.hasMultipleBillingAccounts()) {
              <button
                class="switch-account-btn"
                [routerLink]="'/subscriptions/billing-account-selection/'"
                [queryParams]="{ previousUrl: router.url }">
                Switch Account
              </button>
            }
          </div>
        </div>
      </div>
      @if (billingAccountService.currentBillingAccount(); as currentBillingAccount) {
        <div class="invoices-list-container">
          @if (invoices$ | flyObservableStatus | async; as data) {
            @switch (data.status) {
              @case (ObservableStatus.ERROR) {
                <ng-container [ngTemplateOutlet]="error"></ng-container>
              }
              @case (ObservableStatus.LOADED) {
                <div class="invoices-card-list">
                  @for (invoice of data.value; track invoice.id) {
                    <fly-invoice-card
                      [invoice]="invoice"
                      [billingAccount]="currentBillingAccount">
                    </fly-invoice-card>
                  }
                </div>
                @if (data.value != null && data.value.length > 0) {
                  <fly-invoices-table
                    class="invoices-table"
                    [invoices]="data.value"
                    [billingAccount]="currentBillingAccount">
                  </fly-invoices-table>
                } @else {
                  <ng-container [ngTemplateOutlet]="noInvoices"></ng-container>
                }
              }
              @default {
                <g-loader
                  class="loader"
                  type="dark">
                </g-loader>
              }
            }
          }
        </div>
      } @else if (billingAccountService.billingAccounts()?.length === 0) {
        <ng-container [ngTemplateOutlet]="noInvoices"></ng-container>
      }
      @if (previousUrl != null) {
        <div class="return-to-subscription-management-btn">
          <g-button
            (click)="router.navigateByUrl(previousUrl)"
            class="no-margin"
            size="medium"
            theme="dark"
            type="button">
            RETURN TO SUBSCRIPTION MANAGEMENT
          </g-button>
        </div>
      }
    </div>
  </div>
} @else if (billingAccountService.currentState() === 'ERROR') {
  <ng-container [ngTemplateOutlet]="error"></ng-container>
} @else {
  <fly-full-page-loader></fly-full-page-loader>
}

<ng-template #error>
  <g-alert
    closeable="false"
    type="error"
    slotAlign="center">
    An error occurred while loading your invoices. Please try again.
  </g-alert>
</ng-template>

<ng-template #noInvoices>
  <p
    >Can't find your invoice or receipt? Check <a [href]="accountSubscriptionsUrl">{{ accountSubscriptionsUrl }}</a
    >.</p
  >
</ng-template>
