<fly-subscription-management
  [title]="'Database Subscriptions'"
  subscriptionType="DATABASES">
  <div contentBody>
    <router-outlet></router-outlet>
  </div>
  <img
    icon
    alt="database subscriptions icon"
    class="image"
    src="assets/img/db.svg" />
  <g-button
    button
    [href]="catalogUrl"
    class="buy-databases-button">
    Buy Databases
  </g-button>
</fly-subscription-management>
