import { Component, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { nullAndUndefinedFilter } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { filter, Observable, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ObservableStatus } from '../../../../shared/enums/observable-status-enum';
import { Invoice } from '../../../../shared/models/subscriptions/invoice.model';
import { AccountsService } from '../../../../shared/services/accounts.service';
import { BillingAccountService } from '../../services/billing-account.service';

@Component({
  selector: 'fly-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  standalone: false
})
export class InvoicesComponent {
  protected readonly activatedRoute = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  private readonly accountsService = inject(AccountsService);
  protected readonly billingAccountService = inject(BillingAccountService);
  readonly ObservableStatus = ObservableStatus;
  readonly accountSubscriptionsUrl = `${environment.garminUrl}/account/subscriptions/`;
  readonly previousUrl = this.activatedRoute.snapshot.queryParams['previousUrl'];
  protected invoices$: Observable<Invoice[]> = toObservable(this.billingAccountService.currentBillingAccount).pipe(
    filter(nullAndUndefinedFilter),
    switchMap((billingAccount) => this.accountsService.getInvoices(billingAccount.id))
  );
}
