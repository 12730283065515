<div class="subscription-container">
  <h1 class="uppercase">Subscriptions</h1>
  <div class="subscription-card-container">
    <div class="subscription-card">
      <img
        alt="Databases"
        src="/assets/img/features/subscription-management/databases.svg" />
      <g-button
        (click)="navigate('/subscriptions/databases', 'FG')"
        theme="light">
        Database Subscriptions
      </g-button>
    </div>
    @if (gpAutoRenewalEnabled) {
      <div class="subscription-card">
        <img
          alt="Garmin Pilot"
          src="/assets/img/features/subscription-management/garmin-pilot.svg" />
        <g-button
          (click)="navigate('/subscriptions/garmin-pilot', 'FG')"
          theme="light">
          Garmin Pilot Subscriptions
        </g-button>
      </div>
    }
    <div class="subscription-card">
      <img
        alt="Connext"
        src="/assets/img/features/subscription-management/connext.svg" />
      <g-button
        (click)="navigate('/connext/satellite-services/', 'FLY')"
        theme="light">
        Connected-Cockpit Subscriptions
      </g-button>
    </div>
  </div>
</div>
