import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SlotManagementPageComponent } from '@garmin-avcloud/avcloud-fly-web-common/autorenewals';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { AuthGuard } from '@garmin-avcloud/avcloud-web-utils';
import { featureGuard } from '@garmin-avcloud/avcloud-web-utils/feature';
import { EnableAutorenewalComponent } from './components/enable-autorenewal/enable-autorenewal.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { MobileDeviceDetailsComponent } from './components/mobile-device-details/mobile-device-details.component';
import { MobileDevicesListComponent } from './components/mobile-devices-list/mobile-devices-list.component';
import { SelectBillingAccountComponent } from './components/select-billing-account/select-billing-account.component';
import { AvdbSubscriptionManagementComponent } from './pages/avdb-subscription-management/avdb-subscription-management.component';
import { AvdbSubscriptionsComponent } from './pages/avdb-subscription-management/subpages/avdb-subscriptions/avdb-subscriptions.component';
import { ChangePaymentMethodComponent } from './pages/change-payment-method/change-payment-method.component';
import { GarminPilotSubscriptionManagementComponent } from './pages/gp-subscription-management/gp-subscription-management.component';
import { GarminPilotSubscriptionsComponent } from './pages/gp-subscription-management/subpages/gp-subscriptions/gp-subscriptions.component';
import { MobileDeviceManagementComponent } from './pages/gp-subscription-management/subpages/mobile-device-management/mobile-device-management.component';
import { SubscriptionHomeComponent } from './pages/subscription-home/subscription-home.component';

const routes: Routes = [
  {
    path: '',
    component: SubscriptionHomeComponent
  },
  {
    path: 'databases',
    canActivate: [AuthGuard],
    component: AvdbSubscriptionManagementComponent,
    children: [
      {
        path: 'active-subscriptions',
        component: AvdbSubscriptionsComponent
      },
      {
        path: 'expired-subscriptions',
        canActivate: [featureGuard],
        data: {
          feature: FeatureFlyGarmin.AVDB_EXPIRED_SUBSCRIPTIONS
        },
        component: AvdbSubscriptionsComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'active-subscriptions'
      }
    ]
  },
  {
    path: 'manage-payment-method/:billingAccountId',
    canActivate: [AuthGuard],
    component: ChangePaymentMethodComponent
  },
  {
    path: 'invoices',
    canActivate: [AuthGuard],
    component: InvoicesComponent
  },
  {
    path: 'billing-account-selection',
    canActivate: [AuthGuard],
    component: SelectBillingAccountComponent
  },
  {
    path: 'garmin-pilot',
    canActivate: [AuthGuard, featureGuard],
    component: GarminPilotSubscriptionManagementComponent,
    data: {
      feature: FeatureFlyGarmin.GP_AUTO_RENEWALS
    },
    children: [
      {
        //Prevent `SlotManagementPageComponent` from capturing the `/` route.
        path: 'active-subscriptions/',
        pathMatch: 'full',
        redirectTo: 'active-subscriptions'
      },
      {
        path: 'active-subscriptions',
        component: GarminPilotSubscriptionsComponent
      },
      {
        path: 'active-subscriptions/:subscriptionUuid',
        component: SlotManagementPageComponent
      },
      {
        path: 'expired-subscriptions',
        component: GarminPilotSubscriptionsComponent
      },
      {
        path: 'manage-mobile-devices',
        component: MobileDeviceManagementComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: MobileDevicesListComponent
          },
          {
            path: ':uuid',
            component: MobileDeviceDetailsComponent
          }
        ]
      },
      {
        path: 'manage-mobile-devices/:uuid',
        component: GarminPilotSubscriptionsComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'active-subscriptions'
      }
    ]
  },
  {
    path: 'manage/:subscriptionUuid/enable',
    component: EnableAutorenewalComponent,
    canActivate: [featureGuard],
    data: {
      feature: FeatureFlyGarmin.PROMO_CREDITS_TERM_EXTENSIONS
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionManagementRoutingModule {}
