import { Component } from '@angular/core';
import { Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { TABS } from './mobile-device-management.constants';

@Component({
  selector: 'fly-mobile-device-management',
  templateUrl: './mobile-device-management.component.html',
  styleUrls: ['./mobile-device-management.component.scss'],
  standalone: false
})
export class MobileDeviceManagementComponent {
  readonly tabs: Tab[] = TABS;
}
