import { Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { catchError, filter, of, pairwise, switchMap } from 'rxjs';
import { AvdbSubscriptionListService } from 'src/app/features/subscription-management/services/avdb-subscription-list.service';
import { BillingAccountService } from 'src/app/features/subscription-management/services/billing-account.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { SubscriptionJobManagementService } from 'src/app/shared/services/subscription-job-management.service';
import { databasePurchaseUrl } from 'src/app/shared/utilities/urls';
import { TABS } from './avdb-subscriptions.constants';

@Component({
  selector: 'fly-avdb-subscriptions',
  templateUrl: './avdb-subscriptions.component.html',
  styleUrl: './avdb-subscriptions.component.scss',
  standalone: false
})
export class AvdbSubscriptionsComponent implements OnInit {
  readonly subscriptionJobManagementService = inject(SubscriptionJobManagementService);
  readonly billingAccountService: BillingAccountService = inject(BillingAccountService);
  readonly cartService: CartService = inject(CartService);
  private readonly avdbSubscriptionListService = inject(AvdbSubscriptionListService);
  private readonly destroyRef = inject(DestroyRef);
  readonly catalogUrl = databasePurchaseUrl;
  protected activeAvdbSubscriptionCards = toSignal(
    toObservable(this.billingAccountService.currentBillingAccount).pipe(
      switchMap((billingAccount) =>
        billingAccount == null
          ? of([])
          : this.avdbSubscriptionListService.getAvdbSubscriptionCardData(billingAccount.billingAccountId, false)
      ),
      catchError((error) => {
        console.error(error);
        this._currentState().set('ERROR');
        return of(null);
      })
    ),
    { initialValue: null }
  );
  protected expiredAvdbSubscriptionCards = toSignal(
    toObservable(this.billingAccountService.currentBillingAccount).pipe(
      switchMap((billingAccount) =>
        billingAccount == null
          ? of([])
          : this.avdbSubscriptionListService.getAvdbSubscriptionCardData(billingAccount.billingAccountId, true)
      ),
      catchError((error) => {
        console.error(error);
        this._currentState().set('ERROR');
        return of(null);
      })
    ),
    { initialValue: null }
  );
  private readonly computedCurrentState = computed(() =>
    this.activeAvdbSubscriptionCards() == null || this.expiredAvdbSubscriptionCards() == null ? 'LOADING' : 'LOADED'
  );
  private readonly _currentState = computed(() => signal(this.computedCurrentState()));
  protected currentState = computed(() => this._currentState()());
  protected readonly currentCart = toSignal(this.cartService.getCurrentCart());

  protected isWebview: boolean = false;
  readonly tabs: Tab[] = TABS;
  selectedTabRoute: string | string[] = this.tabs[0].route;

  ngOnInit(): void {
    this.currentTab();
  }

  constructor(private readonly router: Router) {
    toObservable(this.subscriptionJobManagementService.serviceStatus)
      .pipe(
        filter((status) => status === 'LOADED'),
        switchMap(() => this.subscriptionJobManagementService.listenForJobsByActionType('grant', this.destroyRef)),
        pairwise(),
        takeUntilDestroyed()
      )
      .subscribe(([previousPendingJobs, currentPendingJobs]) => {
        if (
          (previousPendingJobs ?? []).some(
            (previousJob) => !(currentPendingJobs ?? []).some((currentJob) => currentJob.id === previousJob.id)
          )
        ) {
          this.avdbSubscriptionListService.refreshAvdbSubscriptionCardData();
        }
      });
  }

  currentTab(): void {
    const currentPath = this.router.url;

    if (currentPath === this.tabs[0].route) {
      this.selectedTabRoute = this.tabs[0].route;
    } else {
      this.selectedTabRoute = this.tabs[1].route;
    }
  }
}
