import { Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';

export const TABS: Tab[] = [
  {
    displayText: 'active subscriptions',
    route: 'active-subscriptions',
    isActiveMatchOptions: {
      matrixParams: 'exact',
      queryParams: 'exact',
      paths: 'subset',
      fragment: 'exact'
    }
  },
  {
    displayText: 'expired subscriptions',
    route: 'expired-subscriptions'
  },
  {
    displayText: 'manage mobile devices',
    route: 'manage-mobile-devices',
    isActiveMatchOptions: {
      matrixParams: 'exact',
      queryParams: 'exact',
      paths: 'subset',
      fragment: 'exact'
    }
  }
];
