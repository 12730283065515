import { Component, inject, OnInit } from '@angular/core';
import { LocalStorageService, OAuthTokenResponse, LocalStorageKey } from '@garmin-avcloud/avcloud-web-utils';
import { databasePurchaseUrl } from '../../../../shared/utilities/urls';

@Component({
  selector: 'fly-avdb-subscription-management',
  templateUrl: './avdb-subscription-management.component.html',
  styleUrl: './avdb-subscription-management.component.scss',
  standalone: false
})
export class AvdbSubscriptionManagementComponent implements OnInit {
  private readonly localStorageService = inject(LocalStorageService);
  readonly catalogUrl = databasePurchaseUrl;

  ngOnInit(): void {
    const customerGuid = this.localStorageService.get<OAuthTokenResponse>(
      LocalStorageKey.OAuthTokenResponse
    )?.customerId;
    if (customerGuid == null) {
      throw new Error('Cannot get database subscriptions without valid customerGuid.');
    }
  }
}
