import { AfterViewInit, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { LocalStorageKey, LocalStorageService, OAuthTokenResponse } from '@garmin-avcloud/avcloud-web-utils';
import { CoreDataGpSubscriptionsService } from '../../../../shared/services/coredata-gp-subscriptions.service';
import { GpDeviceRegistrationsService } from '../../../../shared/services/gp-device-registrations.service';
import { gpCatalogUrl } from '../../../../shared/utilities/urls';
import { TABS } from './gp-subscription-management.constants';
import { RedeemPromoCodeModalComponent } from './subpages/redeem-promo-code-modal/redeem-promo-code-modal.component';

@Component({
  selector: 'fly-gp-subscription-management',
  templateUrl: './gp-subscription-management.component.html',
  styleUrls: ['./gp-subscription-management.component.scss'],
  standalone: false
})
export class GarminPilotSubscriptionManagementComponent implements OnInit, AfterViewInit {
  @ViewChild('redeemPromoCode') redeemPromoCode: RedeemPromoCodeModalComponent;

  readonly tabs: Tab[] = TABS;
  readonly catalogUrl = gpCatalogUrl;

  private readonly coreDataGpSubscriptionsService = inject(CoreDataGpSubscriptionsService);
  private readonly gpDeviceRegistrationsService = inject(GpDeviceRegistrationsService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly destroyRef = inject(DestroyRef);
  shouldOpenRedeemModal: boolean = false;

  ngOnInit(): void {
    const customerGuid = this.localStorageService.get<OAuthTokenResponse>(
      LocalStorageKey.OAuthTokenResponse
    )?.customerId;
    if (customerGuid == null) {
      throw new Error('Cannot get GP subscriptions without valid customerGuid.');
    }

    this.coreDataGpSubscriptionsService.initGpSubscriptions(customerGuid);
    this.gpDeviceRegistrationsService.initDeviceRegistrations(customerGuid);

    this.activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (params['redeem'] === 'true') {
        this.shouldOpenRedeemModal = true;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.shouldOpenRedeemModal && this.redeemPromoCode != null) {
      this.openRedeemModal();
    }
  }

  openRedeemModal(): void {
    this.redeemPromoCode.open();
  }
}
