import { Component, Input } from '@angular/core';
import { BillingAccount } from '../../../../shared/models/subscriptions/billing-account.model';
import { Invoice } from '../../../../shared/models/subscriptions/invoice.model';

@Component({
  selector: 'fly-invoice-card',
  templateUrl: './invoice-card.component.html',
  styleUrls: ['./invoice-card.component.scss'],
  standalone: false
})
export class InvoiceCardComponent {
  @Input({ required: true }) invoice: Invoice;
  @Input({ required: true }) billingAccount: BillingAccount;
}
