import { DOCUMENT } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AvdbPricingBundleType, CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { SubscriptionSource } from '@garmin-avcloud/avcloud-fly-web-common/api/src/models/subscriptions-service/subscription-source.type';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { forkJoin } from 'rxjs';
import { CartAddItemRequest } from 'src/app/shared/models/cart/cart-add-item-request';
import { Cart } from 'src/app/shared/models/cart/cart.model';
import { AvdbSubscriptionCard } from '../../../../shared/models/subscriptions/avdb-subscription-card.model';
import { SubscriptionCardAbstract } from '../../abstracts/subscription-card.abstract';
import { isFutureStartDate } from '../../utilities/is-future-start-date.utility';

@Component({
  selector: 'fly-avdb-subscription-card',
  templateUrl: './avdb-subscription-card.component.html',
  styleUrls: ['../subscription-card/subscription-card.component.scss', './avdb-subscription-card.component.scss'],
  standalone: false
})
export class AvdbSubscriptionCardComponent extends SubscriptionCardAbstract implements OnInit {
  @Input({ required: true }) initialCardData: AvdbSubscriptionCard;
  @Input() currentCart?: Cart;

  override cardData: AvdbSubscriptionCard;
  override readonly showAutoRenewToggle: boolean = true;

  private readonly featuresService = inject(FeaturesService);
  private readonly document = inject(DOCUMENT);

  protected presentInCurrentCart: boolean = false;

  readonly isFutureStartDate = isFutureStartDate;

  itemForAddingToCurrentCart: CartAddItemRequest;
  addToCurrentCartFailed: boolean = false;
  deviceId?: number;
  yarmouthRenewalPriceEnabled: boolean = false;
  earlyRenewalEnabled: boolean = false;
  isBundleImprovementsEnabled: boolean = false;
  hasFutureStartDate: boolean = false;
  updateStartDateFailed: boolean = false;
  subSourceGrantTypes: SubscriptionSource[] = ['AVDB_GRANT', 'GP_GRANT'];

  ngOnInit(): void {
    this.cardData = this.initialCardData;
    this.createItemForAddingToCurrentCart();
    this.presentInCurrentCart =
      this.currentCart == null
        ? false
        : this.currentCart?.avdbCart.some((item) => item.product.partKey === this.cardData.product.sku) ||
          this.currentCart?.avdbBundleCart.some((item) => item.bundle.partKey === this.cardData.product.sku);
    forkJoin({
      yarmouthRenewalPriceActive: this.featuresService.isFeatureActive(
        FeatureFlyGarmin.SUBSCRIPTIONS_YARMOUTH_RENEWAL_PRICE
      ),
      earlyRenewalActive: this.featuresService.isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_EARLY_RENEWAL),
      bundleImprovementsActive: this.featuresService.isFeatureActive(FeatureFlyGarmin.REDEMPTION_IMPROVEMENTS)
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ yarmouthRenewalPriceActive, earlyRenewalActive, bundleImprovementsActive }) => {
          this.yarmouthRenewalPriceEnabled = yarmouthRenewalPriceActive;
          this.earlyRenewalEnabled = earlyRenewalActive;
          this.isBundleImprovementsEnabled = bundleImprovementsActive;
        },
        error: (err) => {
          console.error('Error fetching feature flags:', err);
        }
      });

    this.hasFutureStartDate = isFutureStartDate(this.cardData.subscriptionData.startDate);

    super.init();
  }

  private createItemForAddingToCurrentCart(): void {
    if (this.cardData.deviceLinkUrl != null) {
      this.deviceId = Number(this.cardData.deviceLinkUrl.split('/').pop());
    }

    const deviceID =
      this.cardData.product.productType === CartItemType.DATABASE ||
      this.cardData.product.bundleType === AvdbPricingBundleType.DEVICE
        ? (Number(this.cardData.coveredAircraftSystems?.at(0)?.legacyDeviceId) ?? undefined) ||
          (this.deviceId ?? undefined)
        : undefined;

    const aircraftID =
      this.cardData.product.bundleType === AvdbPricingBundleType.ONEPAK ||
      this.cardData.product.bundleType === AvdbPricingBundleType.PILOTPAK
        ? (this.cardData.aircraftTailNumber ?? undefined)
        : undefined;

    this.itemForAddingToCurrentCart = {
      itemType: this.cardData.product.productType,
      partNumber: this.cardData.subscriptionData.sku,
      aircraftID,
      deviceID,
      existingSubscriptionUuid: this.cardData.subscriptionData.subscriptionUuid
    };
  }

  navigateToAircraftDetails(url: string): void {
    this.document.location.href = url;
  }
}
