import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'fly-subscription-home',
  templateUrl: './subscription-home.component.html',
  styleUrls: ['./subscription-home.component.scss'],
  standalone: false
})
export class SubscriptionHomeComponent implements OnInit {
  protected gpAutoRenewalEnabled = false;
  private readonly featuresService = inject(FeaturesService);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.GP_AUTO_RENEWALS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => (this.gpAutoRenewalEnabled = enabled));
  }

  navigate(path: string, site: 'FG' | 'FLY'): void {
    if (site === 'FG') {
      this.router.navigateByUrl(path);
    } else {
      this.document.location.href = `${environment.flyLegacyUrl}${path}`;
    }
  }
}
