import { Component, computed, inject, Input } from '@angular/core';
import { BillingAccountService } from '../../services/billing-account.service';

@Component({
  selector: 'fly-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.scss'],
  standalone: false
})
export class SubscriptionManagementComponent {
  readonly billingAccountService: BillingAccountService = inject(BillingAccountService);
  @Input({ required: true }) title: string;
  @Input({ required: true }) subscriptionType: 'GP' | 'DATABASES';
  showBillingSection = computed(
    () =>
      this.billingAccountService.currentState() === 'LOADED' &&
      !(this.billingAccountService.currentBillingAccount()?.billingAccountId ?? '').includes('NON-BILLING-ACCOUNT')
  );
}
