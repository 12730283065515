<h1 class="header">Enable Autorenewal</h1>
<div>
  @switch (currentState()) {
    @case ('LOADING') {
      <g-loader
        type="dark"
        class="loader">
      </g-loader>
    }

    @case ('LOADED') {
      @if (isPendingJob) {
        <div>
          <p class="pending-job-text">Hold short. We're enabling auto-renew for your subscription.</p>
          <g-button
            class="back-btn"
            theme="light"
            size="medium"
            (click)="location.back()">
            RETURN TO SUBSCRIPTION LIST
          </g-button>
        </div>
      } @else {
        @if (subscription.data.endDate != null) {
          <p class="page-text"
            >By enabling auto-renewal for your
            <span class="product-name"> {{ subscriptionSkuProduct.display }} </span>
            , your payment method on file will be automatically charged on
            {{ subscription.data.endDate | flyFormattedDate }}.
          </p>
        }
      }
    }

    @case ('ERROR') {
      <p class="page-text"
        >An error occurred while attempting to load your subscription. Please refresh this page and try again.</p
      >
    }
  }
</div>
