<div class="card-container">
  <fly-invoice-link
    [invoice]="invoice"
    [billingAccount]="billingAccount">
  </fly-invoice-link>
  <div class="invoice-info">
    <span class="bold-text">Invoice Date:</span>
    <span>{{ invoice.createDateUTC | flyFormattedDate }}</span>
  </div>
  <div class="invoice-info">
    <span class="bold-text">Due Date:</span>
    <span>{{ invoice.dueDateUTC | flyFormattedDate }}</span>
  </div>
  <div class="invoice-info">
    <span class="bold-text">Status:</span>
    <span>{{ invoice.status }}</span>
  </div>
</div>
