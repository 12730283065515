<fly-subscription-management
  [title]="'Garmin Pilot Subscriptions'"
  subscriptionType="GP">
  <div contentBody>
    <router-outlet></router-outlet>
  </div>
  <img
    icon
    alt="Garmin Pilot"
    class="gp-icon"
    src="/assets/img/features/subscription-management/garmin-pilot.svg" />
  <g-button
    button
    [href]="catalogUrl"
    class="button">
    Add Subscription
  </g-button>
  <g-button
    button
    (click)="redeemPromoCode.open()"
    class="button"
    (click)="openRedeemModal()">
    Redeem Promo Code
  </g-button>
</fly-subscription-management>
<fly-redeem-promo-code-modal #redeemPromoCode></fly-redeem-promo-code-modal>
