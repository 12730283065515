<div class="subscription-list-container">
  @if (avdbSubscriptionCards() | flyObservableStatus | async; as data) {
    @switch (data.status) {
      @case (ObservableStatus.LOADED) {
        @if (data.value?.length === 0) {
          <p> There are no subscriptions that match the specified filters. </p>
        } @else {
          @for (avdbSubscriptionCard of data.value; track avdbSubscriptionCard.subscriptionData.subscriptionUuid) {
            @if (!avdbSubscriptionCard.isExpired) {
              <fly-avdb-subscription-card
                [initialCardData]="avdbSubscriptionCard"
                [currentCart]="currentCart">
              </fly-avdb-subscription-card>
            } @else {
              @if (avdbSubscriptionCard.isExpired) {
                <fly-avdb-subscription-card-expired
                  [initialCardData]="avdbSubscriptionCard"
                  [currentCart]="currentCart">
                </fly-avdb-subscription-card-expired>
              }
            }
          }
        }
      }
      @case (ObservableStatus.ERROR) {
        <span>
          An error occurred while fetching the subscription data. Please refresh or contact
          <a routerLink="/aviation-support">Aviation Product Support</a>
          if the issue continues.
        </span>
      }
      @default {
        <g-loader
          class="loader"
          type="dark">
        </g-loader>
      }
    }
  }
</div>
