import { Component, computed, input, Input } from '@angular/core';
import { BillingAccount } from '../../../../shared/models/subscriptions/billing-account.model';
import { Invoice } from '../../../../shared/models/subscriptions/invoice.model';

@Component({
  selector: 'fly-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
  standalone: false
})
export class InvoicesTableComponent {
  invoices = input.required<Invoice[]>();
  protected sortedInvoices = computed(() =>
    this.invoices().sort(
      (invoiceA, invoiceB) => new Date(invoiceB.dueDateUTC).getTime() - new Date(invoiceA.dueDateUTC).getTime()
    )
  );
  @Input({ required: true }) billingAccount: BillingAccount;
}
