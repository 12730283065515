import { computed, inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { nullAndUndefinedFilter } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { filter, forkJoin, map, Observable, switchMap } from 'rxjs';
import { BillingAccount } from '../../../shared/models/subscriptions/billing-account.model';
import { PaymentMethod } from '../../../shared/models/subscriptions/payment-method.model';
import { YarmouthAviationSubscriptionsPaymentService } from '../../../shared/services/yarmouth-aviation-subscriptions-payment.service';
import { BillingAccountService } from './billing-account.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  private readonly billingAccountService: BillingAccountService = inject(BillingAccountService);
  protected readonly paymentService = inject(YarmouthAviationSubscriptionsPaymentService);

  readonly yarmouthPaymentMethodsByAccountId = toSignal(
    toObservable(this.billingAccountService.billingAccounts).pipe(
      filter(nullAndUndefinedFilter),
      switchMap((billingAccounts) => this.getPaymentMethodPerBillingAccount(billingAccounts))
    )
  );

  readonly currentYarmouthPaymentMethod = computed(() => {
    const currentBillingAccount = this.billingAccountService.currentBillingAccount();

    if (currentBillingAccount == null) {
      return null;
    }

    return this.yarmouthPaymentMethodsByAccountId()?.get(currentBillingAccount.id);
  });

  getPaymentMethodPerBillingAccount(billingAccounts: BillingAccount[]): Observable<Map<number, PaymentMethod | null>> {
    const accountIdAndPaymentMethodPairs$ = billingAccounts
      .filter((billingAccount) => billingAccount.isNonBillingAccount !== true)
      .map((billingAccount) =>
        this.paymentService
          .getPaymentInfo(billingAccount.id)
          .pipe(map((paymentMethod) => ({ accountId: billingAccount.id, paymentMethod })))
      );

    return forkJoin(accountIdAndPaymentMethodPairs$).pipe(
      map((accountIdAndPaymentInfoPairs) => {
        const paymentMethodByAccountIdMap = new Map<number, PaymentMethod | null>();
        accountIdAndPaymentInfoPairs.forEach(({ accountId, paymentMethod }) =>
          paymentMethodByAccountIdMap.set(accountId, paymentMethod)
        );
        return paymentMethodByAccountIdMap;
      })
    );
  }
}
