import { Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import {
  ACTIVE_SUBSCRIPTIONS_TAB,
  EXPIRED_SUBSCRIPTIONS_TAB,
  MANAGE_MOBILE_DEVICES_TAB
} from '../shared/tabs.constants';

export const TABS: Tab[] = [
  {
    ...ACTIVE_SUBSCRIPTIONS_TAB,
    route: '../active-subscriptions'
  },
  {
    ...EXPIRED_SUBSCRIPTIONS_TAB,
    route: '../expired-subscriptions'
  },
  {
    ...MANAGE_MOBILE_DEVICES_TAB,
    route: '.'
  }
];
