import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { BillingAccount } from '../../../../shared/models/subscriptions/billing-account.model';
import { Invoice } from '../../../../shared/models/subscriptions/invoice.model';
import { AccountsService } from '../../../../shared/services/accounts.service';

enum State {
  LOADING,
  LOADED,
  ERROR
}

@Component({
  selector: 'fly-invoice-link',
  templateUrl: './invoice-link.component.html',
  styleUrls: ['./invoice-link.component.scss'],
  standalone: false
})
export class InvoiceLinkComponent implements OnInit {
  @Input({ required: true }) invoice: Invoice;
  @Input({ required: true }) billingAccount: BillingAccount;
  @ViewChild('invoiceLink') invoiceLink: ElementRef<HTMLAnchorElement>;
  invoiceUrl: string;
  currentState: State = State.LOADING;
  readonly State = State;
  private readonly accountService = inject(AccountsService);

  ngOnInit(): void {
    this.accountService.getInvoicePdf(this.billingAccount.id, this.invoice.id).subscribe({
      next: (pdfBlob) => {
        this.invoiceUrl = URL.createObjectURL(pdfBlob);
        this.currentState = State.LOADED;
      },
      error: () => (this.currentState = State.ERROR)
    });
  }

  clickInvoiceLink(): void {
    this.invoiceLink.nativeElement.click();
  }
}
