<g-collapsible-region class="subscriptions-sort-and-filter-menu">
  <span
    slot="heading"
    class="subscriptions-sort-and-filter-menu--title">
    FILTER AND SORT
  </span>
  <g-flex
    slot="body"
    flexDirection="column"
    class="subscriptions-sort-and-filter-menu--options-container">
    @switch (currentState) {
      @case (State.LOADING) {
        <g-skeleton class="sort-by-options--skeleton"></g-skeleton>
        <g-skeleton class="filter-options--skeleton"></g-skeleton>
      }
      @case (State.LOADED) {
        <g-drop-down label="Sort By">
          <select [formControl]="formGroup.controls.sortOption">
            <option [value]="SortOption.EXPIRATION_ASC">{{ SortOption.EXPIRATION_ASC }}</option>
            <option [value]="SortOption.EXPIRATION_DESC">{{ SortOption.EXPIRATION_DESC }}</option>
            <option [value]="SortOption.NAME_ASC">{{ SortOption.NAME_ASC }}</option>
            <option [value]="SortOption.NAME_DESC">{{ SortOption.NAME_DESC }}</option>
          </select>
        </g-drop-down>
        @for (filter of filters; track filter.name) {
          <g-flex flexDirection="column">
            <span class="filter-options--header">{{ filter.name }}</span>
            @for (filterOption of filter.options; track filterOption.name) {
              <g-flex class="filter-options">
                <g-checkbox [label]="filterOption.name | flyFilterName">
                  <!-- linthtml-disable input-req-label -->

                  @switch (filter.name) {
                    @case (FilterName.AIRCRAFT) {
                      <input
                        [formControl]="formGroup.controls.aircraftFilters.controls[filterOption.value]"
                        [name]="filterOption.name"
                        type="checkbox" />
                    }
                    @case (FilterName.PORTABLE) {
                      <input
                        [formControl]="formGroup.controls.portableDeviceFilters.controls[filterOption.value]"
                        [name]="filterOption.name"
                        type="checkbox" />
                    }
                    @case (FilterName.SUBSCRIPTION) {
                      <input
                        [formControl]="formGroup.controls.subscriptionTypeFilters.controls[filterOption.value]"
                        [name]="filterOption.name"
                        type="checkbox" />
                    }
                    @case (FilterName.MOBILE_DEVICE) {
                      <input
                        [formControl]="formGroup.controls.subscriptionTypeFilters.controls[filterOption.value]"
                        [name]="filterOption.name"
                        type="checkbox" />
                    }
                  }

                  <!-- linthtml-enable input-req-label -->
                </g-checkbox>
                <span>({{ filterOption.count }})</span>
              </g-flex>
            }
          </g-flex>
        }
      }
      @case (State.ERROR) {
        <p class="error-text">An error occurred that prevents filtering and sorting.</p>
      }
    }
  </g-flex>
</g-collapsible-region>
