@if (showBanner()) {
  <div
    [ngClass]="processingCompleted() ? 'complete' : 'in-progress'"
    class="banner-container">
    <strong>
      {{ bannerText() }}
    </strong>
    @if (latestCompletedCart != null) {
      <div>
        <small>Purchase Date: {{ latestCompletedCart.checkoutCompletedAt | date: 'medium' }}</small>
      </div>
    }
  </div>
}
