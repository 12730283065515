<fly-subscription-card>
  <ng-container firstContent>
    <span class="subscription-card--title">{{ cardData.product.display }}</span>
    @if (cardData.product.avdbType != null) {
      <g-flex class="subscription-card--small-text">
        <span class="no-wrap-text">{{ cardData.product.avdbType }}</span>
      </g-flex>
    }
    @if (cardData.aircraftTailNumber != null) {
      <g-flex class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Covered Aircraft:</span>
        @if (cardData.aircraftLinkUrl != null) {
          <a
            [href]="cardData.aircraftLinkUrl"
            class="no-wrap-text">
            {{ cardData.aircraftTailNumber }}
          </a>
        }
      </g-flex>
    }
    @if (cardData.deviceName != null && cardData.deviceLinkUrl != null && cardData.deviceSerial) {
      <div class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Covered Device:</span>
        <a
          [href]="cardData.deviceLinkUrl"
          class="no-wrap-text">
          {{ cardData.deviceName }} ({{ cardData.deviceSerial }})
        </a>
      </div>
    }
    @if (cardData.subscriptionData.endDate != null && cardData.subscriptionData.endDate !== '') {
      <div class="subscription-card--field-value-text subscription-card--small-text">
        <span class="subscription-card--bold-text no-wrap-text">Expired Date:</span>
        <span class="no-wrap-text">{{ cardData.subscriptionData.endDate | flyFormattedDate }}</span>
      </div>
    }
  </ng-container>

  <ng-container lastContent>
    <div class="last-content">
      @if (eligibleToAddToCart) {
        <fly-add-to-cart-button
          [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
          [presentInCurrentCart]="presentInCurrentCart"
          (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
        </fly-add-to-cart-button>
      }
    </div>
  </ng-container>
</fly-subscription-card>
