import { Location } from '@angular/common';
import { Component, DestroyRef, inject, input, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SkuProduct, SubscriptionResponse, YarmouthSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import {
  ComponentsGlobalWebModule,
  ComponentState,
  FormattedDatePipe
} from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { switchMap } from 'rxjs';
import { SubscriptionCard } from 'src/app/shared/models/subscriptions/subscription-card.model';
import { SkuService } from '../../../../shared/services/sku.service';
import { SubscriptionsGeneralService } from '../../../../shared/services/subscriptions-general.service';

@Component({
  selector: 'fly-enable-autorenewal',
  imports: [ComponentsGlobalWebModule, FormattedDatePipe, RouterLink],
  templateUrl: './enable-autorenewal.component.html',
  styleUrl: './enable-autorenewal.component.scss'
})
export class EnableAutorenewalComponent implements OnInit {
  readonly cardData = input.required<SubscriptionCard>();

  protected currentState: WritableSignal<ComponentState> = signal('LOADING');
  protected readonly location = inject(Location);

  private readonly route = inject(ActivatedRoute);
  private readonly subscriptionsGeneralService = inject(SubscriptionsGeneralService);
  private readonly skuService = inject(SkuService);
  private readonly destroyRef = inject(DestroyRef);

  subscription: SubscriptionResponse<YarmouthSubscription>;
  subscriptionSkuProduct: SkuProduct;
  isPendingJob: boolean = false;

  ngOnInit(): void {
    if (this.cardData().pendingJobAction?.name === 'initialize-billing-account') {
      this.currentState.set('LOADED');
      this.isPendingJob = true;
    }

    const subscriptionUuid = this.route.snapshot.params.subscriptionUuid;

    if (subscriptionUuid == null) {
      throw new Error('Subscription cannot be found.');
    }

    this.subscriptionsGeneralService
      .getSubscriptionByUuid(subscriptionUuid)
      .pipe(
        switchMap((subscription) => {
          this.subscription = subscription;
          return this.skuService.getSkuProducts(this.subscription.data.sku);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (skuProducts) => {
          this.subscriptionSkuProduct = skuProducts[0] ?? null;

          if (this.subscriptionSkuProduct != null) {
            this.currentState.set('LOADED');
          } else {
            this.currentState.set('ERROR');
          }
        },
        error: () => {
          this.currentState.set('ERROR');
        }
      });
  }
}
