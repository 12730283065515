import { Component, computed, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { SiteConfigService } from '@garmin-avcloud/avcloud-fly-web-common/config';
import { catchError, forkJoin, map, of, repeat, takeWhile } from 'rxjs';
import { Cart } from '../../../../shared/models/cart/cart.model';
import { CartService } from '../../../../shared/services/cart.service';
import { AvdbSubscriptionListService } from '../../services/avdb-subscription-list.service';
import {
  CART_POLLING_INTERVAL_SECONDS,
  ORDER_PROCESSED_MESSAGE,
  ORDER_PROCESSING_MESSAGE
} from './cart-fullfillment-banner.constants';

@Component({
  selector: 'fly-cart-fulfillment-banner',
  templateUrl: './cart-fulfillment-banner.component.html',
  styleUrls: ['./cart-fulfillment-banner.component.scss'],
  standalone: false
})
export class CartFulfillmentBannerComponent implements OnInit {
  readonly avdbSubscriptionListService = inject(AvdbSubscriptionListService);
  latestCompletedCart: Cart | null = null;
  showBanner: WritableSignal<boolean> = signal(false);
  processingCompleted: WritableSignal<boolean> = signal(false);
  bannerText: Signal<string> = computed(() =>
    this.processingCompleted() ? ORDER_PROCESSED_MESSAGE : ORDER_PROCESSING_MESSAGE
  );

  private readonly cartService = inject(CartService);
  private readonly siteConfigService = inject(SiteConfigService);

  ngOnInit(): void {
    forkJoin({
      latestCompletedCart: this.cartService.getLatestCheckoutCompletedCart().pipe(catchError(() => of(null))),
      completedCartNoticeTimeMinutes: this.siteConfigService.getSiteConfig().pipe(
        map((siteConfig) => siteConfig.completedCartNoticeTimeMinutes ?? 15),
        catchError(() => of(15)) // Default fallback value in case somehow site config explodes
      )
    })
      .pipe(
        repeat({ delay: CART_POLLING_INTERVAL_SECONDS * 1000 }),
        takeWhile(() => !this.processingCompleted())
      )
      .subscribe(({ latestCompletedCart, completedCartNoticeTimeMinutes }) => {
        if (latestCompletedCart == null) {
          return;
        }

        if (latestCompletedCart.yarmouthProcessedAt == null && latestCompletedCart.checkoutCompletedAt != null) {
          this.latestCompletedCart = latestCompletedCart;
          const bannerDisplayTime = new Date(latestCompletedCart.checkoutCompletedAt);
          const bannerDisplayWindow = bannerDisplayTime.getTime() + completedCartNoticeTimeMinutes * 60 * 1000;
          // Use set time as opposed to setHour due to unexpected results when ending DST.
          bannerDisplayTime.setTime(bannerDisplayWindow);

          if (new Date() < bannerDisplayTime) {
            this.showBanner.set(true);
          }
        } else {
          this.processingCompleted.set(true);
          if (this.showBanner()) {
            this.avdbSubscriptionListService.refreshAvdbSubscriptionCardData();
          }
        }
      });
  }
}
