import { Component, Input, OnInit } from '@angular/core';
import { AvdbPricingBundleType, CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { CartAddItemRequest } from 'src/app/shared/models/cart/cart-add-item-request';
import { Cart } from 'src/app/shared/models/cart/cart.model';
import { AvdbSubscriptionCard } from 'src/app/shared/models/subscriptions/avdb-subscription-card.model';
import { SubscriptionCardAbstract } from '../../abstracts/subscription-card.abstract';

@Component({
  selector: 'fly-avdb-subscription-card-expired',
  templateUrl: './avdb-subscription-card-expired.component.html',
  styleUrls: [
    '../subscription-card/subscription-card.component.scss',
    './avdb-subscription-card-expired.component.scss'
  ],
  standalone: false
})
export class AvdbSubscriptionCardExpiredComponent extends SubscriptionCardAbstract implements OnInit {
  @Input({ required: true }) initialCardData: AvdbSubscriptionCard;
  @Input() currentCart?: Cart;
  override cardData: AvdbSubscriptionCard;
  override readonly showAutoRenewToggle: boolean = true;
  protected presentInCurrentCart: boolean = false;
  itemForAddingToCurrentCart: CartAddItemRequest;
  addToCurrentCartFailed: boolean = false;
  deviceId?: number;
  eligibleToAddToCart: boolean = false;

  ngOnInit(): void {
    this.cardData = this.initialCardData;
    this.avdbSubscriptionListService
      .getAvdbSubscriptionCardData(this.cardData.subscriptionData.billingAccountId)
      .subscribe((subscriptions) => {
        const existingSubscription = (subscriptions ?? []).some(
          (subscription) =>
            subscription.subscriptionData.sku === this.cardData.subscriptionData.sku &&
            ((this.cardData.deviceSerial != null && subscription.deviceSerial === this.cardData.deviceSerial) ||
              (this.cardData.aircraftTailNumber != null &&
                subscription.aircraftTailNumber === this.cardData.aircraftTailNumber)) &&
            !subscription.isExpired
        );

        if (existingSubscription) {
          this.eligibleToAddToCart = false;
        } else {
          this.eligibleToAddToCart = true;
          this.createItemForAddingToCurrentCart();
        }
      });
    this.presentInCurrentCart =
      this.currentCart == null
        ? false
        : this.currentCart?.avdbCart.some(
            (item) =>
              item.product.partKey === this.cardData.product.sku &&
              item.device.aircraft?.id === this.cardData.aircraftTailNumber
          ) ||
          this.currentCart?.avdbBundleCart.some(
            (item) =>
              item.bundle.partKey === this.cardData.product.sku &&
              item.aircraft?.id === this.cardData.aircraftTailNumber
          );
    super.init();
  }

  private createItemForAddingToCurrentCart(): void {
    if (this.cardData.deviceLinkUrl != null) {
      this.deviceId = Number(this.cardData.deviceLinkUrl.split('/').pop());
    }

    this.itemForAddingToCurrentCart = {
      itemType: this.cardData.product.productType,
      partNumber: this.cardData.subscriptionData.sku,
      aircraftID:
        this.cardData.product.bundleType === AvdbPricingBundleType.PILOTPAK ||
        this.cardData.product.bundleType === AvdbPricingBundleType.ONEPAK
          ? (this.cardData.aircraftTailNumber ?? undefined)
          : undefined,
      deviceID:
        this.cardData.product.productType === CartItemType.DATABASE ||
        this.cardData.product.bundleType === AvdbPricingBundleType.DEVICE
          ? (this.deviceId ?? undefined)
          : undefined
    };
  }
}
