import { Injectable } from '@angular/core';
import { CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { AvdbSubscriptionTypeEnum } from '../../../shared/enums/avdb-subscription-type.enum';
import { AvdbSubscriptionCard } from '../../../shared/models/subscriptions/avdb-subscription-card.model';
import { SubscriptionCardsFilterAndSortServiceAbstract } from '../abstracts/subscription-cards-filter-and-sort-service.abstract';
import { FilterName } from '../enums/filter-name.enum';

@Injectable({
  providedIn: 'root'
})
export class AvdbSubscriptionCardsFilterAndSortService extends SubscriptionCardsFilterAndSortServiceAbstract<AvdbSubscriptionCard> {
  filterSubscriptionCards(subscriptionCards: AvdbSubscriptionCard[]): AvdbSubscriptionCard[] {
    return subscriptionCards
      .filter(
        (subscriptionCard) =>
          this.filters.get(FilterName.AIRCRAFT)?.includes(subscriptionCard.aircraftTailNumber ?? '') ?? true
      )
      .filter(
        (subscriptionCard) =>
          this.filters
            .get(FilterName.PORTABLE)
            ?.some((filter) => subscriptionCard.subscriptionData.devices?.some((device) => device.id === filter)) ??
          true
      )
      .filter((subscriptionCard) => {
        if (subscriptionCard.product.productType === CartItemType.DATABASE) {
          return (
            this.filters.get(FilterName.SUBSCRIPTION)?.includes(AvdbSubscriptionTypeEnum.INDIVIDUAL_DATABASE) ?? true
          );
        } else {
          return this.filters.get(FilterName.SUBSCRIPTION)?.includes(subscriptionCard.product.bundleType ?? '') ?? true;
        }
      });
  }
}
