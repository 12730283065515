import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentMethod } from '../models/subscriptions/payment-method.model';

@Injectable({
  providedIn: 'root'
})
export class YarmouthAviationSubscriptionsPaymentService {
  private readonly httpClient = inject(HttpClient);
  private readonly baseUrl = `${environment.flyLegacyUrl}/api/v2/subscriptions/payment`;

  // Returns credit card details
  getCreditCard(accountId: number): Observable<PaymentMethod> {
    return this.httpClient.get<PaymentMethod>(`${this.baseUrl}/info/${accountId}`);
  }

  // Returns true if user has valid payment method on file
  hasValidPaymentInfo(accountId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl}/info/User/${accountId}/Valid`);
  }

  // Returns user's default payment info
  // Will return null if accountId is a non-billing number
  getPaymentInfo(accountId: number): Observable<PaymentMethod | null> {
    return this.httpClient
      .get<PaymentMethod>(`${this.baseUrl}/PaymentInfo/User/${accountId}`)
      .pipe(catchError(this.handleError<any>(null)));
  }

  private handleError<T>(result: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result);
    };
  }
}
