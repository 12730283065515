import { Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BillingAccount } from '../../../../shared/models/subscriptions/billing-account.model';
import { PaymentMethodService } from '../../services/payment-method-service';

@Component({
  selector: 'fly-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
  standalone: false
})
export class AccountManagementComponent {
  @Input({ required: true }) billingAccount: BillingAccount;
  @Input({ required: true }) multipleBillingAccounts: boolean;
  @Input({ required: true }) showBillingSection: boolean;
  @Input({ required: true }) subscriptionType: 'GP' | 'DATABASES';
  @Output() readonly switchAccountClicked = new EventEmitter<void>();
  @Output() readonly updatePaymentMethodClicked = new EventEmitter<void>();

  protected readonly router = inject(Router);
  protected readonly paymentMethodService = inject(PaymentMethodService);

  readonly paymentExpirationClass = computed(() => {
    const currentPaymentMethod = this.paymentMethodService.currentYarmouthPaymentMethod();
    if (currentPaymentMethod == null) {
      return '';
    }

    const now = new Date();
    // ExpirationMonth in Javascript is the month after the one we want, when inputted to the new Date() function.
    // Since we are seeking the last day of the ExpirationMonth in the payment information, the 0th day will underflow safely.
    // It will also safely overflow Month 12 to January of the following year.
    const expiry = new Date(currentPaymentMethod.ExpirationYear, currentPaymentMethod.ExpirationMonth, 0);
    const threeMonths = new Date();
    threeMonths.setMonth(now.getMonth() + 3);

    if (expiry < now) {
      return 'payment-expiration-danger';
    } else if (expiry < threeMonths) {
      return 'payment-expiration-warning';
    } else {
      return '';
    }
  });

  readonly paymentDetails = computed(() => {
    const currentPaymentMethod = this.paymentMethodService.currentYarmouthPaymentMethod();
    if (currentPaymentMethod == null) {
      return '';
    }

    const adjective = this.paymentExpirationClass() === 'payment-expiration-danger' ? 'expired' : 'expires';

    return `${currentPaymentMethod.CardType} ${currentPaymentMethod.LastFourDigits} - ${adjective} ${currentPaymentMethod.ExpirationMonth.toString().padStart(
      2,
      '0'
    )}/${currentPaymentMethod.ExpirationYear}`;
  });
}
