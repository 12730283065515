import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  IsGarminPilotSubscriptionPipe,
  ManageMobileDeviceComponent,
  MobileDeviceCardComponent,
  PromoCodeWizardComponent
} from '@garmin-avcloud/avcloud-fly-web-common/autorenewals';
import { ComponentsGlobalWebModule, TabsComponent } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { SharedModule } from 'src/app/shared/shared.module';
import { BillingAccountSelectionComponent } from '../purchasing/cart/components/billing-account-selection/billing-account-selection.component';
import { AccountManagementComponent } from './components/account-management/account-management.component';
import { AddToCartButtonComponent } from './components/add-to-cart-button/add-to-cart-button.component';
import { AutoRenewalModalComponent } from './components/auto-renewal-modal/auto-renewal-modal.component';
import { AutoRenewalToggleComponent } from './components/auto-renewal-toggle/auto-renewal-toggle.component';
import { AvdbSubscriptionCardComponent } from './components/avdb-subscription-card/avdb-subscription-card.component';
import { AvdbSubscriptionCardExpiredComponent } from './components/avdb-subscription-card-expired/avdb-subscription-card-expired.component';
import { AvdbSubscriptionsFilterAndSortMenuComponent } from './components/avdb-subscriptions-filter-and-sort-menu/avdb-subscriptions-filter-and-sort-menu.component';
import { AvdbSubscriptionsListComponent } from './components/avdb-subscriptions-list/avdb-subscriptions-list.component';
import { CancelSubscriptionModalComponent } from './components/cancel-subscription-modal/cancel-subscription-modal.component';
import { CartFulfillmentBannerComponent } from './components/cart-fulfillment-banner/cart-fulfillment-banner.component';
import { GarminPilotSubscriptionCardComponent } from './components/garmin-pilot-subscription-card/garmin-pilot-subscription-card.component';
import { GarminPilotSubscriptionCardExpiredComponent } from './components/garmin-pilot-subscription-card-expired/garmin-pilot-subscription-card-expired.component';
import { GarminPilotSubscriptionsListComponent } from './components/garmin-pilot-subscriptions-list/garmin-pilot-subscriptions-list.component';
import { GpSubscriptionsFilterAndSortMenuComponent } from './components/gp-subscriptions-filter-and-sort-menu/gp-subscriptions-filter-and-sort-menu.component';
import { InvoiceCardComponent } from './components/invoice-card/invoice-card.component';
import { InvoiceLinkComponent } from './components/invoice-link/invoice-link.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoicesTableComponent } from './components/invoices-table/invoices-table.component';
import { MobileDeviceDetailsComponent } from './components/mobile-device-details/mobile-device-details.component';
import { MobileDeviceSubscriptionsListComponent } from './components/mobile-device-subscriptions-list/mobile-device-subscriptions-list.component';
import { MobileDevicesListComponent } from './components/mobile-devices-list/mobile-devices-list.component';
import { RenewSubscriptionButtonComponent } from './components/renew-subscription-button/renew-subscription-button.component';
import { SelectBillingAccountComponent } from './components/select-billing-account/select-billing-account.component';
import { StartSubscriptionConfirmationModalComponent } from './components/start-subscription-confirmation-modal/start-subscription-confirmation-modal.component';
import { SubscriptionCardComponent } from './components/subscription-card/subscription-card.component';
import { SubscriptionManagementComponent } from './components/subscription-management/subscription-management.component';
import { SubscriptionManagementPageHeaderComponent } from './components/subscription-management-page-header/subscription-management-page-header.component';
import { AvdbSubscriptionManagementComponent } from './pages/avdb-subscription-management/avdb-subscription-management.component';
import { AvdbSubscriptionsComponent } from './pages/avdb-subscription-management/subpages/avdb-subscriptions/avdb-subscriptions.component';
import { ChangePaymentMethodComponent } from './pages/change-payment-method/change-payment-method.component';
import { GarminPilotSubscriptionManagementComponent } from './pages/gp-subscription-management/gp-subscription-management.component';
import { GarminPilotSubscriptionsComponent } from './pages/gp-subscription-management/subpages/gp-subscriptions/gp-subscriptions.component';
import { MobileDeviceManagementComponent } from './pages/gp-subscription-management/subpages/mobile-device-management/mobile-device-management.component';
import { PromoCodeRedemptionComponent } from './pages/gp-subscription-management/subpages/promo-code-redemption/promo-code-redemption.component';
import { RedeemPromoCodeModalComponent } from './pages/gp-subscription-management/subpages/redeem-promo-code-modal/redeem-promo-code-modal.component';
import { SubscriptionHomeComponent } from './pages/subscription-home/subscription-home.component';
import { FilterNamePipe } from './pipes/filter-name.pipe';
import { IsStringNonEmptyPipe } from './pipes/is-string-non-empty.pipe';
import { MobileDeviceNamesPipe } from './pipes/mobile-device-names.pipe';
import { SubscriptionCardAlertComponent } from './subscription-card-alert/subscription-card-alert.component';
import { SubscriptionManagementRoutingModule } from './subscription-management-routing.module';

@NgModule({
  declarations: [
    AccountManagementComponent,
    AddToCartButtonComponent,
    AutoRenewalToggleComponent,
    AvdbSubscriptionCardComponent,
    AvdbSubscriptionsComponent,
    AvdbSubscriptionManagementComponent,
    AvdbSubscriptionsFilterAndSortMenuComponent,
    AvdbSubscriptionsListComponent,
    AvdbSubscriptionCardExpiredComponent,
    CancelSubscriptionModalComponent,
    CartFulfillmentBannerComponent,
    ChangePaymentMethodComponent,
    FilterNamePipe,
    GarminPilotSubscriptionCardComponent,
    GarminPilotSubscriptionsListComponent,
    GarminPilotSubscriptionManagementComponent,
    GarminPilotSubscriptionCardExpiredComponent,
    GarminPilotSubscriptionsComponent,
    GpSubscriptionsFilterAndSortMenuComponent,
    InvoiceCardComponent,
    InvoiceLinkComponent,
    InvoicesComponent,
    InvoicesTableComponent,
    IsStringNonEmptyPipe,
    MobileDeviceDetailsComponent,
    MobileDeviceNamesPipe,
    MobileDevicesListComponent,
    MobileDeviceManagementComponent,
    MobileDeviceSubscriptionsListComponent,
    PromoCodeRedemptionComponent,
    RedeemPromoCodeModalComponent,
    RenewSubscriptionButtonComponent,
    SelectBillingAccountComponent,
    SubscriptionCardComponent,
    SubscriptionHomeComponent,
    SubscriptionManagementComponent,
    SubscriptionManagementPageHeaderComponent,
    SubscriptionCardAlertComponent
  ],
  exports: [AddToCartButtonComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ComponentsGlobalWebModule,
    IsGarminPilotSubscriptionPipe,
    ManageMobileDeviceComponent,
    MobileDeviceCardComponent,
    PromoCodeWizardComponent,
    RouterModule,
    SharedModule,
    SubscriptionManagementRoutingModule,
    StartSubscriptionConfirmationModalComponent,
    TabsComponent,
    AutoRenewalModalComponent,
    BillingAccountSelectionComponent
  ]
})
export class SubscriptionManagementModule {}
