@switch (currentState) {
  @case (State.LOADING) {
    <g-skeleton class="loading"> </g-skeleton>
  }
  @case (State.LOADED) {
    <div
      class="invoice-link-container"
      (click)="clickInvoiceLink()">
      <a
        #invoiceLink
        [href]="invoiceUrl"
        target="_blank">
        PDF
      </a>
      <img
        alt="invoice download icon"
        class="download-icon"
        src="/assets/garmin-regalia-icons/download/icon.svg" />
    </div>
  }
  @case (State.ERROR) {
    <span class="error"> Unable to load PDF </span>
  }
}
