import { AvdbPricingBundleType } from '@garmin-avcloud/avcloud-fly-web-common/api';

enum SkuNonBundleType {
  INDIVIDUAL_DATABASE = 'Individual Database'
}

export type AvdbSubscriptionType = (typeof AvdbSubscriptionTypeEnum)[keyof typeof AvdbSubscriptionTypeEnum];

export const AvdbSubscriptionTypeEnum = {
  ...SkuNonBundleType,
  ...AvdbPricingBundleType
};
