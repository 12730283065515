import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BillingAccountService } from '../../services/billing-account.service';

@Component({
  selector: 'fly-select-billing-account',
  styleUrl: './select-billing-account-component.scss',
  templateUrl: './select-billing-account.component.html',
  standalone: false
})
export class SelectBillingAccountComponent {
  protected readonly activatedRoute = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  readonly billingAccountService = inject(BillingAccountService);
  readonly previousUrl = this.activatedRoute.snapshot.queryParams['previousUrl'] ?? '/subscriptions';
  checkIcon = faCheck;
}
