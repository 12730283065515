import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, map } from 'rxjs';
import { BillingAccount } from 'src/app/shared/models/subscriptions/billing-account.model';
import { AccountsService } from 'src/app/shared/services/accounts.service';
import { SubscriberService } from 'src/app/shared/services/subscriber.service';
import { environment } from 'src/environments/environment';

enum State {
  Loading,
  Loaded,
  SubscriberNotFound
}

@Component({
  selector: 'fly-change-payment-method',
  templateUrl: './change-payment-method.component.html',
  styleUrls: ['./change-payment-method.component.scss'],
  standalone: false
})
export class ChangePaymentMethodComponent implements OnInit {
  private readonly accountsService: AccountsService = inject(AccountsService);
  private readonly subscriberService: SubscriberService = inject(SubscriberService);

  private readonly GENERIC_ROUTE = '/subscriptions';
  private readonly GP_ROUTE = '/subscriptions/garmin-pilot';
  private readonly DATABASES_ROUTE = '/subscriptions/databases';

  State = State;
  currentState: State = State.Loading;

  returnToSubscriptionPage: 'GP' | 'DATABASES' | null = null;
  billingAccountId: string;
  billingAccount: BillingAccount;
  returnToSubscriptionManagementLink: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.billingAccountId = this.route.snapshot.params.billingAccountId;

    const passedInReturnToSubscriptionPage: any = this.route.snapshot.queryParams.returnToSubscriptionPage;
    this.returnToSubscriptionPage =
      passedInReturnToSubscriptionPage === 'GP' || passedInReturnToSubscriptionPage === 'DATABASES'
        ? passedInReturnToSubscriptionPage
        : null;

    this.returnToSubscriptionManagementLink =
      this.returnToSubscriptionPage === 'GP'
        ? this.GP_ROUTE
        : this.returnToSubscriptionPage === 'DATABASES'
          ? this.DATABASES_ROUTE
          : this.GENERIC_ROUTE;

    if (this.billingAccountId != null) {
      forkJoin([
        this.accountsService
          .getAccounts()
          .pipe(map((accounts) => accounts.find((account) => account.billingAccountId == this.billingAccountId))),
        this.subscriberService.getSubscriberUuid(this.billingAccountId)
      ]).subscribe({
        next: ([billingAccount, subscriberGuid]) => {
          if (billingAccount == null) {
            this.currentState = State.SubscriberNotFound;
          } else {
            this.billingAccount = billingAccount;
            this.loadScript(subscriberGuid);
          }
        },
        error: () => (this.currentState = State.SubscriberNotFound)
      });
    } else {
      this.currentState = State.SubscriberNotFound;
    }
  }

  loadScript(subscriberGuid: string): void {
    const body = document.body;
    const script = document.createElement('script');
    script.src = environment.payFrameSrc;
    body.appendChild(script);

    script.onload = (): void => {
      this.currentState = State.Loaded;
      const script2 = document.createElement('script');
      script2.lang = 'javascript';
      script2.innerHTML = `
        var lib = new UpcFrameClient();
        var cardStylesCustomization = {
            base: {
                color: "#fff",
                fontSize: "14px",
                lineHeight: "14px",
                fontSmoothing: "antialiased"
            },
            placeholder: {
                color: "#d8d8d8"
            }
        };

        var paymentFrameConfig =
        {
            frameContainerId: "frameContainer_upc",
            amount: 0,
            isMoto: false,
            shopperLocale: "en-US",
            frameHeight: "448px",
            successCallback: hostOnSuccess,
            errorCallback: hostOnError,
            isRecurringChecked: false,
            isExistentUser: true,
            resetHeight: hostResetHeight,
            useExternalReferenceGuid: true,
            hideRecurringCheck: false,
            unauthorizedCallback: hostOnUnauthorized,
            shopperReference: "${subscriberGuid}",
            asyncPaymentMethods: true,
            callerUrl: window.location.href,
            returnUrl: window.location.href
        };

        lib.createframe(paymentFrameConfig);

        function hostOnSuccess(data) {
            console.log("Data from host "+ data);
            console.log("hostOnSuccess");
        }

        function hostOnError(data) {
            console.log("Data from host "+ data);
            console.log("hostOnError");
        }

        function reLoadDropIn() {
            upcLibrary.reInitDropin(newPaymentFrameConfig);
        }

        function hostGetAditionalInfo() {
            upcLibrary.updateInfo(newPaymentFrameConfig);
        }

        function hostOnUnauthorized() {
            console.log("hostOnUnauthorized");
        }

        function hostResetHeight(actualHeight) {
          document.getElementById("upc-frame").height = actualHeight;
        }
      `;

      body.appendChild(script2);
    };
  }
}
