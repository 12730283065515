<table>
  <caption class="invoices-table-caption">Invoices Table</caption>
  <thead>
    <tr>
      <th>Invoice Date</th>
      <th>Due Date</th>
      <th class="invoices-table-info">New Charges</th>
      <th class="invoices-table-info">Taxes</th>
      <th class="invoices-table-info">Credits</th>
      <th>Balance Due</th>
      <th>Invoice</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    @for (invoice of sortedInvoices(); track invoice.id) {
      <tr>
        <td>{{ invoice.createDateUTC | flyFormattedDate }}</td>
        <td>{{ invoice.dueDateUTC | flyFormattedDate }}</td>
        <td class="invoices-table-inf">{{ invoice.currentCharges | currency }}</td>
        <td class="invoices-table-inf">{{ invoice.taxes | currency }}</td>
        <td class="invoices-table-inf">{{ invoice.credits | currency }}</td>
        <td>{{ invoice.amountDue | currency }}</td>
        <td>
          <fly-invoice-link
            [invoice]="invoice"
            [billingAccount]="billingAccount">
          </fly-invoice-link>
        </td>
        <td>{{ invoice.status }}</td>
      </tr>
    }
  </tbody>
</table>
