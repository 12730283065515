import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { LocalStorageService, OAuthTokenResponse, LocalStorageKey } from '@garmin-avcloud/avcloud-web-utils';

@Component({
  selector: 'fly-redeem-promo-code-modal',
  templateUrl: './redeem-promo-code-modal.component.html',
  standalone: false
})
export class RedeemPromoCodeModalComponent implements OnInit {
  @ViewChild(ModalComponent) modal: ModalComponent;
  protected customerGuid: string = '';
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly router = inject(Router);

  ngOnInit(): void {
    this.customerGuid =
      this.localStorageService.get<OAuthTokenResponse>(LocalStorageKey.OAuthTokenResponse)?.customerId ?? '';
  }

  navigateToManageMobileDevices(uuid: string | null): void {
    if (uuid != null) {
      this.router.navigate([uuid], { relativeTo: this.activatedRoute.parent });
    }
  }

  open(): void {
    this.modal.open();
  }
}
