import { Component, inject, Input, input } from '@angular/core';
import { Cart } from 'src/app/shared/models/cart/cart.model';
import { ObservableStatus } from '../../../../shared/enums/observable-status-enum';
import { AvdbSubscriptionCard } from '../../../../shared/models/subscriptions/avdb-subscription-card.model';
import { AvdbSubscriptionCardsFilterAndSortService } from '../../services/avdb-subscription-cards-filter-and-sort.service';

@Component({
  selector: 'fly-avdb-subscriptions-list',
  templateUrl: './avdb-subscriptions-list.component.html',
  styleUrl: './avdb-subscriptions-list.component.scss',
  standalone: false
})
export class AvdbSubscriptionsListComponent {
  private readonly avdbSubscriptionListFilterAndSortService = inject(AvdbSubscriptionCardsFilterAndSortService);
  protected readonly ObservableStatus = ObservableStatus;
  @Input() currentCart?: Cart;

  avdbSubscriptionCards = input.required({
    transform: (avdbSubscriptionCards: AvdbSubscriptionCard[] | null) =>
      this.avdbSubscriptionListFilterAndSortService.filterAndSortSubscriptionCards(avdbSubscriptionCards ?? [])
  });
}
