<div class="container">
  <div class="content-container">
    <ng-content select="[icon]"></ng-content>
    <div class="title-container">
      <h1>{{ title }}</h1>
      <ng-content select="[button]"></ng-content>
    </div>
  </div>
  <fly-cart-fulfillment-banner></fly-cart-fulfillment-banner>
  @if (currentBillingAccount; as currentBillingAccount) {
    <fly-account-management
      [billingAccount]="currentBillingAccount"
      [multipleBillingAccounts]="multipleBillingAccounts"
      [showBillingSection]="showBillingSection"
      [subscriptionType]="subscriptionType">
    </fly-account-management>
  }
</div>
<hr />
