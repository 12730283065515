<div class="account-billing-container">
  <div class="account-section">
    <span class="account-title">Account</span>
    <span class="account-title-value">{{ billingAccount | flyAccountDisplay }}</span>
    @if (multipleBillingAccounts) {
      <button
        class="switch-account-btn"
        [routerLink]="'/subscriptions/billing-account-selection/'"
        [queryParams]="{ previousUrl: router.url }">
        Switch Account
      </button>
    }
  </div>
  @if (showBillingSection) {
    <div class="billing-section">
      <span class="billing-title">Billing</span>
      <div class="payment-section-with-icon">
        <img
          alt="update payment method icon"
          class="update-payment-icon"
          src="assets/img/card.svg" />
        <button
          class="update-payment-button"
          [routerLink]="'/subscriptions/manage-payment-method/' + billingAccount.billingAccountId"
          [queryParams]="{ returnToSubscriptionPage: subscriptionType }">
          Payment
        </button>
      </div>
      @if (paymentDetails().length > 0) {
        <div class="payment-section-without-icon">
          <span [class]="paymentExpirationClass()">
            {{ paymentDetails() }}
          </span>
        </div>
      }
      <div class="payment-section-with-icon">
        <img
          alt="invoices icon"
          class="invoices-icon"
          src="assets/img/invoices.svg" />
        <button
          class="invoices-button"
          [routerLink]="'/subscriptions/invoices/'"
          [queryParams]="{ previousUrl: router.url }">
          Invoices
        </button>
      </div>
    </div>
  }
</div>
