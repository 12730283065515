<div class="containert">
  <div class="px-8">
    <h1 class="update-payment-method-header">Update Payment Method</h1>

    @if (currentState !== State.Loading) {
      <div>
        <div class="billing-account-info">
          <span class="font-bold">Billing Account:</span>
          <span>{{ billingAccount | flyAccountDisplay }}</span>
        </div>
        <p class="note">
          Note: All of your subscriptions will use this payment method when they renew. This includes aviation
          databases, Garmin Pilot, and connected-cockpit subscriptions.
        </p>
      </div>
    }
  </div>

  @if (currentState === State.Loading) {
    <g-flex class="loading">
      <g-loader
        class="w-20"
        type="dark">
      </g-loader>
    </g-flex>
  }

  @if (currentState === State.SubscriberNotFound) {
    <g-alert
      class="not-found"
      show="true"
      closeable="false"
      type="warning"
      slotAlign="center">
      We were unable to load your account. Please try again or contact
      <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
    </g-alert>
  }

  @if (currentState !== State.SubscriberNotFound) {
    <div
      class="not-found"
      id="frameContainer_upc"></div>
  }

  <div class="return-container">
    <g-button
      [routerLink]="returnToSubscriptionManagementLink"
      class="no-margin"
      size="medium"
      theme="dark"
      type="button">
      RETURN TO SUBSCRIPTION MANAGEMENT
    </g-button>
  </div>
</div>
